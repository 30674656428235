var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table_wrapper"},[_c('div',{staticClass:"tab_wrapper d-flex flex-wrap w-100 align-items-center mb-2"},[_c('v-btn',{staticClass:"mx-1",attrs:{"color":"primary","loading":_vm.tabIndis[0].loader},on:{"click":function($event){return _vm.getDataFromApi(0)}}},[_c('span',[_vm._v(_vm._s(_vm.tabIndis[0].name))])]),_c('v-btn',{staticClass:"mx-1",attrs:{"color":"success","loading":_vm.tabIndis[1].loader},on:{"click":function($event){return _vm.getDataFromApi(1)}}},[_c('span',[_vm._v(_vm._s(_vm.tabIndis[1].name))])]),_c('v-btn',{staticClass:"mx-1",attrs:{"color":"error","loading":_vm.tabIndis[2].loader},on:{"click":function($event){return _vm.getDataFromApi(2)}}},[_c('span',[_vm._v(_vm._s(_vm.tabIndis[2].name))])])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.desserts,"sort-by":"c1","loading":_vm.loading,"loading-text":"Loading... Please wait","search":_vm.search,"items-per-page":_vm.perPage,"options":_vm.options,"server-items-length":_vm.totalDesserts},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("48H Deals")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.tabIndis[_vm.activeTab].name))]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.activeTab == 0 || _vm.activeTab == 2)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"color":"success","fab":"","small":"","elevation":"2"},on:{"click":function($event){return _vm.process(item, 'approved')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-check ")])],1)]}}],null,true)},[_c('span',[_vm._v("Approve")])]):_vm._e(),(_vm.activeTab == 0 || _vm.activeTab == 1)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"color":"error","fab":"","small":"","elevation":"2"},on:{"click":function($event){return _vm.process(item, 'rejected')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-close ")])],1)]}}],null,true)},[_c('span',[_vm._v("Reject")])]):_vm._e()]}}],null,true)}),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"indigo","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" Sorry, No Data ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
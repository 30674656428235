<template>
  <div class="table_wrapper">
    <div class="tab_wrapper d-flex flex-wrap w-100 align-items-center mb-2">
      <v-btn
        v-on:click="getDataFromApi(0)"
        color="primary"
        :loading="tabIndis[0].loader"
        class="mx-1"
      >
        <span>{{ tabIndis[0].name }}</span>
      </v-btn>
      <v-btn
        v-on:click="getDataFromApi(1)"
        color="success"
        :loading="tabIndis[1].loader"
        class="mx-1"
      >
        <span>{{ tabIndis[1].name }}</span>
      </v-btn>
      <v-btn
        v-on:click="getDataFromApi(2)"
        color="error"
        :loading="tabIndis[2].loader"
        class="mx-1"
      >
        <span>{{ tabIndis[2].name }}</span>
      </v-btn>
    </div>

    <v-data-table
      :headers="headers"
      :items="desserts"
      sort-by="c1"
      :loading="loading"
      loading-text="Loading... Please wait"
      class="elevation-1"
      :search="search"
      :items-per-page="perPage"
      :options.sync="options"
      :server-items-length="totalDesserts"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>48H Deals</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-toolbar-title>{{ tabIndis[activeTab].name }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left v-if="activeTab == 0 || activeTab == 2">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="process(item, 'approved')"
              color="success"
              class="mx-1"
              fab
              small
              elevation="2"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small>
                mdi-check
              </v-icon>
            </v-btn>
          </template>
          <span>Approve</span>
        </v-tooltip>

        <v-tooltip right v-if="activeTab == 0 || activeTab == 1">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="process(item, 'rejected')"
              color="error"
              class="mx-1"
              fab
              small
              elevation="2"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small>
                mdi-close
              </v-icon>
            </v-btn>
          </template>
          <span>Reject</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-snackbar v-model="snackbar">
      Sorry, No Data
      <template v-slot:action="{ attrs }">
        <v-btn color="indigo" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  name: "48hDeals",
  data() {
    return {
      snackbar: false,
      options: {},
      search: "",
      activeTab: 0,
      loading: true,
      tabIndis: [
        {
          index: 0,
          statu: true,
          val: "pending",
          loader: true,
          name: "Pending",
        },
        {
          index: 1,
          statu: false,
          val: "approved",
          loader: false,
          name: "Approved",
        },
        {
          index: 2,
          statu: false,
          val: "rejected",
          loader: false,
          name: "Rejected",
        },
      ],
      token: localStorage.getItem("authToken"),
      headers: [
        { text: "ID", value: "c1" },
        { text: "Product", value: "c2" },
        { text: "Seller", value: "c3" },
        { text: "Product Original Price", value: "c4" },
        { text: "Discount", value: "c5" },
        { text: "Start Date", value: "c6" },
        { text: "End Date", value: "c7" },
        { text: "Created At", value: "c8" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      desserts: [],
      totalDesserts: 0,
      page: 1,
      perPage: 5,
      itemsData: [],
    };
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi(0);
      },
      deep: true,
    },
  },
  methods: {
    parseLink: function(link) {
      return link.substr(0, 50) + "...";
    },

    process: function(item, type) {
      let url = "";
      if (type == "approved") {
        url =
          process.env.VUE_APP_API_URL +
          "product-offers/" +
          item.id +
          "/approve?api_token=" +
          this.token;
      } else if (type == "rejected") {
        url =
          process.env.VUE_APP_API_URL +
          "product-offers/" +
          item.id +
          "/reject?api_token=" +
          this.token;
      }

      axios.get(url).then((res) => {
        this.getDataFromApi(this.activeTab);
      });
    },
    getDataFromApi(a = null) {
      if (a) {
        this.activeTab = a;
        this.tabIndis[a].loader = true;
      }
      this.loading = true;
      this.fakeApiCall().then((data) => {
        this.desserts = [];
        data.itemsR.forEach((item) => {
          const key = {
            c1: item.id,
            c2: item.product.title,
            c3: item.product.member ? item.product.member.name : "",
            c4: item.product.price_lower - " " + item.product.price_upper,
            c5: item.discount,
            c6: item.deal_start_date,
            c7: item.deal_end_date,
            c8: item.created_at,
            actions: null,
            id: item.id,
          };
          this.desserts.push(key);
        });

        this.tabIndis[this.activeTab].loader = false;
        this.totalDesserts = data.total;
        this.loading = false;
      });
    },
    fakeApiCall() {
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        this.page = page;
        this.perPage = itemsPerPage;

        this.getDesserts().then((res) => {
          const response = res.data;
          if (response && response.status) {
            if (!response.data) {
              this.snackbar = true;
              return false;
            }
            this.itemsData = response.data;
          } else {
            this.snackbar = true;
          }

          const total = res.total;

          let itemsR = this.itemsData;

          setTimeout(() => {
            resolve({
              itemsR,
              total,
            });
          }, 1000);
        });
      });
    },
    getDesserts() {
      const fields = [
        {
          field: "deal_type",
          op: "=",
          value: "48h",
        },
        {
          field: "state",
          op: "=",
          value: this.tabIndis[this.activeTab].val,
        },
      ];

      const sort = [];

      return axios
        .post(
          process.env.VUE_APP_API_URL +
            "product-offers/filter?page=" +
            this.page +
            "&per_page=" +
            this.perPage +
            "&api_token=" +
            this.token,
          {
            fields,
            sort: sort,
            type: "",
          }
        )
        .then(function(response) {
          return response.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
  mounted() {},
  computed: {
    ...mapGetters("auth", ["user"]),
  },
};
</script>
